<script>
import Chart from "@/components/widgets/chart";
import { api } from "@/state/services";

export default {
  components: { Chart },
  data() {
    return {
      empty: true,

      resume: {
        paid: 0,
        prediction: 0,
        total: 0
      },

      chartdata: {},
      options: {}
    }
  },
  methods: {
    getChart() {
      api
        .get('chart/boletos')
        .then(response => {
          if (response.data.status == 'success') {
            this.resume = response.data.chart.resume;

            this.chartdata = {
              labels: response.data.chart.labels,
              datasets: [
                {
                  label: 'Total',
                  backgroundColor: ["#16A085", "#ea9400", "#ff0000"],
                  data: response.data.chart.data,
                }
              ]
            }

            this.options = {
              defaultFontColor: '#6DA544',
              responsive: true,
              legend: {
                display: false
              },
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    ticks: {
                      maxTicksLimit: 10,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      max: response.data.chart.max,
                      min: 0,
                      stepSize: response.data.chart.step,
                    },
                  },
                ],
              },
            }

            this.empty = false
          } else {
            this.empty = true
          }
        })
    }
  },
  mounted() {
    this.getChart();
  },
}
</script>

<template>
  <div class="w-100">
    <div v-if="empty"></div>
    <div v-else>
      <Chart :chartdata="chartdata" :options="options"></Chart>
    </div>
  </div>
</template>