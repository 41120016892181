<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import moment from "moment";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";
import ChartGraduation from "@/components/widgets/chart-graduation.vue";
import ChartBoletos from "@/components/widgets/chart-boletos.vue";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Referral link': 'Link de Indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Graduation': 'Graduación',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
    ChartGraduation,
    ChartBoletos,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      month_year: moment().format("MM/YY"),

      statData: null,

      referral: process.env.VUE_APP_URL_PAINEL + this.$store.state.account.user.indication,

      total: {
        prediction: "R$ -,--",
      },

      ranking: {
        adesoes: {
          qty: "-",
          list: null,
        },
        boletospagos: {
          qty: "-",
          total: "R$ -,--",
          list: null,
        },
        boletosavencer: {
          qty: "-",
          total: "R$ -,--",
          list: null,
        },
        boletosvencidos: {
          qty: "-",
          total: "R$ -,--",
          list: null,
        },
        inadimplencia: {
          qty: "-",
          total: "R$ -,--",
          list: null,
        },
        ticketmedio: {
          geral: "R$ -,--",
          media: "R$ -,--",
          list: null,
        }
      }
    };
  },
  methods: {
    getPrediction() {
      api
        .get('reports/bonus/prediction')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.prediction = this.$options.filters.currency(response.data.total);

            this.getStatData();
          }
        })
    },
    getAdesoes() {
      api
        .get('ranking/adesoes')
        .then(response => {
          if (response.data.status == 'success') {
            this.ranking.adesoes.qty = this.$options.filters.points(response.data.qty);
            this.ranking.adesoes.list = response.data.list;

            this.getStatData();
          }
        })
    },
    getBoletosPagos() {
      api
        .get('ranking/boletospagos')
        .then(response => {
          if (response.data.status == 'success') {
            this.ranking.boletospagos.qty = this.$options.filters.points(response.data.qty);
            this.ranking.boletospagos.total = this.$options.filters.currency(response.data.total);
            this.ranking.boletospagos.list = response.data.list;

            this.getStatData();
          }
        })
    },
    getBoletosVencidos() {
      api
        .get('ranking/boletosvencidos')
        .then(response => {
          if (response.data.status == 'success') {
            this.ranking.boletosvencidos.qty = this.$options.filters.points(response.data.qty);
            this.ranking.boletosvencidos.total = this.$options.filters.currency(response.data.total);
            this.ranking.boletosvencidos.list = response.data.list;

            this.getStatData();
          }
        })
    },
    getInadimplencia() {
      api
        .get('ranking/inadimplencia')
        .then(response => {
          if (response.data.status == 'success') {
            this.ranking.inadimplencia.qty = this.$options.filters.points(response.data.qty);
            this.ranking.inadimplencia.total = this.$options.filters.currency(response.data.total);
            this.ranking.inadimplencia.list = response.data.list;

            this.getStatData();
          }
        })
    },
    getTicketMedio() {
      api
        .get('ranking/ticketmedio')
        .then(response => {
          if (response.data.status == 'success') {
            this.ranking.ticketmedio.geral = this.$options.filters.currency(response.data.geral);
            this.ranking.ticketmedio.media = this.$options.filters.currency(response.data.media);
            this.ranking.ticketmedio.list = response.data.list;

            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          type: "line",
          icon: "bx-user",
          color: "",
          title: "Tipo de Conta",
          value: this.account.user.package,
        },
        {
          type: "line",
          icon: "bxs-graduation",
          color: "",
          title: "Qualificação do Mês",
          value: "-",
        },
        {
          type: "line",
          icon: "bx-dollar",
          color: "",
          title: "Adesões do Mês",
          value: "<span>" + this.ranking.adesoes.qty + "</span>"
        },
        {
          type: "line",
          icon: "bx-calendar",
          color: "",
          title: "Previsão do Mês",
          value: this.total.prediction,
        },
        {
          type: "line",
          icon: "bx-barcode",
          color: "success",
          title: "Boletos Pagos",
          value: "<span>" + this.ranking.boletospagos.total + "</span><span>" + this.ranking.boletospagos.qty + "</span>",
        },
        {
          type: "line",
          icon: "bx-user-plus",
          color: "danger",
          title: "Boletos Vencidos",
          value: "<span>" + this.ranking.boletosvencidos.total + "</span><span>" + this.ranking.boletosvencidos.qty + "</span>"
        },
        {
          type: "line",
          icon: "bx-dollar-circle",
          color: "danger",
          title: "Inadimplência – 1º Boleto",
          value: "<span>" + this.ranking.inadimplencia.qty + "</span>"
        },
        {
          type: "line",
          icon: "bx-dollar",
          color: "success",
          title: "Ticket Médio",
          value: "<span>" + this.ranking.ticketmedio.geral + "</span>"
        },
      ]
    },
  },
  mounted() {
    this.getPrediction()
    this.getAdesoes()
    this.getBoletosPagos()
    this.getBoletosVencidos()
    this.getInadimplencia()
    this.getTicketMedio()
    this.getStatData()
  }
};
</script>

<template>
  <Layout>
    <Banners></Banners>

    <div class="card">
      <div class="card-body py-2 d-flex align-items-center">
        <div class="mt-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z">
            </path>
            <path
              d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z">
            </path>
          </svg>
        </div>
        <template v-if="account.user.indication">
          <h5 class="mb-0 ml-2">Link de Indicação</h5>
          <div class="mt-1 ml-3">
            <ShareNetwork network="whatsapp" url="" :title="referral">
              <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
            </ShareNetwork>
          </div>
          <div class="mt-1 ml-2">
            <button class="btn btn-link p-0 m-0" v-clipboard:copy="referral
              " v-on:click="$noty.success('O link foi copiado!')">
              <i class="bx bx-copy font-size-20 text-dark"></i>
            </button>
          </div>
        </template>
        <template v-else>
          <div class="py-2 ml-2">
            <h5 class="mb-0">Link de Indicação</h5>
            <router-link tag="a" to="/account/indication">
              Você ainda não possui um link de indicação, <u>clique aqui</u> para criar.
            </router-link>
          </div>
        </template>
      </div>
    </div>

    <div class="row">
      <div v-for="(row, index) of statData" :key="index" class="col-md-3 col-lg-3 col-xl-3">
        <Stat :type="row.type" :icon="row.icon" :color="row.color" :month="row.month" :title="row.title" :value="row.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <fieldset class="card-h-fix">
          <legend>
            Qualificação do Mês <sup class="font-weight-light">{{ month_year }}</sup>
          </legend>
          <div class="d-flex justify-content-center card-h-fix">
            <ChartGraduation />
          </div>
        </fieldset>
      </div>
      <div class="col-md-6">
        <fieldset class="card-h-fix">
          <legend>Boletos Pagos <sup class="font-weight-light">{{ month_year }}</sup></legend>
          <div class="d-flex flex-wrap flex-gap-10 mb-4">
            <div class="flex-fill">
              <div class="card mb-0">
                <div class="card-body text-center p-2">
                  <div class="">
                    <h5 class="my-1">{{ this.ranking.boletospagos.total}}</h5>
                    <h5 class="my-1">{{ this.ranking.boletospagos.qty }}</h5>
                  </div>
                  <div class="bg-light rounded py-1 font-size-12 text-uppercase">Pagos</div>
                </div>
              </div>
            </div>
            <div class="flex-fill">
              <div class="card mb-0">
                <div class="card-body text-center p-2">
                  <div class="">
                    <h5 class="my-1">{{ this.ranking.boletosavencer.total }}</h5>
                    <h5 class="my-1">{{ this.ranking.boletosavencer.qty }}</h5>
                  </div>
                  <div class="bg-light rounded py-1 font-size-12 text-uppercase">À vencer</div>
                </div>
              </div>
            </div>
            <div class="flex-fill">
              <div class="card mb-0">
                <div class="card-body text-center p-2">
                  <div class="">
                    <h5 class="my-1">{{ this.ranking.boletosvencidos.total }}</h5>
                    <h5 class="my-1">{{ this.ranking.boletosvencidos.qty }}</h5>
                  </div>
                  <div class="bg-light rounded py-1 font-size-12 text-uppercase">Vencidos</div>
                </div>
              </div>
            </div>
          </div>

          <ChartBoletos></ChartBoletos>
        </fieldset>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <fieldset class="card-h-fix">
          <legend>Adesões <sup class="font-weight-light">{{ month_year }}</sup></legend>
          <div class="d-flex flex-wrap flex-gap-10 mb-4">
            <div class="flex-fill">
              <div class="card mb-0">
                <div class="card-body text-center p-2">
                  <h5 class="my-1">{{ this.ranking.adesoes.qty }}</h5>
                  <div class="bg-light rounded py-1 font-size-12 text-uppercase">Qtd</div>
                </div>
              </div>
            </div>
            <div class="d-none flex-fill">
              <div class="card mb-0">
                <div class="card-body text-center p-2">
                  <h5 class="my-1">{{ this.ranking.adesoes.total }}</h5>
                  <div class="bg-light rounded py-1 font-size-12 text-uppercase">Total</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!ranking.adesoes.list" class="text-center">
            <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
          </div>
          <div v-else>
            <div class="d-flex flex-gap-10 justify-content-between align-items-center mb-2" v-for="(row, index) of ranking.adesoes.list" :key="index">
              <div class="d-flex flex-gap-10 align-items-center">
                <span class="ranking-bullet">{{ index + 1 }}</span>
                <span>{{ row.name }}</span>
              </div>
              <div class="d-flex flex-gap-10">
                <span class="badge badge-soft-light font-size-13 text-default">{{ row.qty | points }}</span>
                <span class="d-none">{{ row.total | currency }}</span>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-6">
        <fieldset class="card-h-fix">
          <legend>Boletos Pagos <sup class="font-weight-light">{{ month_year }}</sup></legend>
          <div class="d-flex flex-wrap flex-gap-10 mb-4">
            <div class="flex-fill">
              <div class="card mb-0">
                <div class="card-body text-center p-2">
                  <h5 class="my-1">{{ this.ranking.boletospagos.qty }}</h5>
                  <div class="bg-light rounded py-1 font-size-12 text-uppercase">Qtd</div>
                </div>
              </div>
            </div>
            <div class="flex-fill">
              <div class="card mb-0">
                <div class="card-body text-center p-2">
                  <h5 class="my-1">{{ this.ranking.boletospagos.total }}</h5>
                  <div class="bg-light rounded py-1 font-size-12 text-uppercase">Total</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!ranking.boletospagos.list" class="text-center">
            <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
          </div>
          <div v-else>
            <div class="d-flex flex-gap-10 justify-content-between align-items-center mb-2" v-for="(row, index) of ranking.boletospagos.list" :key="index">
              <div class="d-flex flex-gap-10 align-items-center">
                <span class="ranking-bullet">{{ index + 1}}</span>
                <span>{{ row.name }}</span>
              </div>
              <div class="d-flex flex-gap-10">
                <span class="badge badge-soft-light font-size-13 text-default">{{ row.qty | points }}</span>
                <span>{{ row.total | currency }}</span>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-6">
        <fieldset class="card-h-fix">
          <legend>Inadimplência – 1º Boleto <sup class="font-weight-light">{{ month_year }}</sup></legend>
          <div class="d-flex flex-wrap flex-gap-10 mb-4">
            <div class="flex-fill">
              <div class="card mb-0">
                <div class="card-body text-center p-2">
                  <h5 class="my-1">{{ this.ranking.inadimplencia.qty }}</h5>
                  <div class="bg-light rounded py-1 font-size-12 text-uppercase">Qtd</div>
                </div>
              </div>
            </div>
            <div class="d-none flex-fill">
              <div class="card mb-0">
                <div class="card-body text-center p-2">
                  <h5 class="my-1">{{ this.ranking.inadimplencia.total }}</h5>
                  <div class="bg-light rounded py-1 font-size-12 text-uppercase">Total</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!ranking.inadimplencia.list" class="text-center">
            <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
          </div>
          <div v-else>
            <div class="d-flex flex-gap-10 justify-content-between align-items-center mb-2" v-for="(row, index) of ranking.inadimplencia.list" :key="index">
              <div class="d-flex flex-gap-10 align-items-center">
                <span class="ranking-bullet">{{ index + 1 }}</span>
                <span>{{ row.name }}</span>
              </div>
              <div class="d-flex flex-gap-10">
                <span class="badge badge-soft-light font-size-13 text-default">{{ row.qty | points }}</span>
                <span class="d-none">{{ row.total | currency }}</span>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-6">
        <fieldset class="card-h-fix">
          <legend>Ticket Médio Top 50 <sup class="font-weight-light">{{ month_year }}</sup></legend>
          <div class="d-flex flex-wrap flex-gap-10 mb-4">
            <div class="flex-fill">
              <div class="card mb-0">
                <div class="card-body text-center p-2">
                  <h5 class="my-1">{{ ranking.ticketmedio.media }}</h5>
                  <div class="bg-light rounded py-1 font-size-12 text-uppercase">Valor Médio</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!ranking.ticketmedio.list" class="text-center">
            <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
          </div>
          <div v-else>
            <div class="d-flex flex-gap-10 justify-content-between align-items-center mb-2" v-for="(row, index) of ranking.ticketmedio.list" :key="index">
              <div class="d-flex flex-gap-10 align-items-center">
                <span class="ranking-bullet">{{ index + 1 }}</span>
                <span>{{ row.name }}</span>
              </div>
              <div class="d-flex flex-gap-10">
                <span>{{ row.avg | currency }}</span>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.ranking-bullet {
  background: #27416E;
  border-radius: 100%;
  padding: 2px;
  width: 25px;
  height: 25px;
  color: #fff;
  font-size: 14px;
  text-align: center;
}
</style>